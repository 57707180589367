import axios from "axios";
import AuthService from "../services/auth-service"

const API_URL = process.env.REACT_APP_SERVER_URL+"/api/mission";

const isEmailExist = async (userId, email) => {
    let input = {email:email, userId:userId};
    return axios({
        method:"post",
        url:API_URL + "/check-email",
        data: input,
        headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
    }).catch((error)=>{
        console.log(`Error From check email ${error.response}`);
        return error.response;
      }).then((response) => {
        return response.data;
      });
};

const createMission = async(input) => {
    return axios({
        method:"post",
        url:API_URL + "/add",
        data: input,
        headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
    }).catch((error)=>{
        console.log(`Error From Create mission ${error.response}`);
        return error.response;
      }).then((response) => {
          if(response && response.data && response.data.data && response.data.data.errMsg && response.data.data.errMsg!==''){
              alert(response.data.data.errMsg);
          }
        return response.data;
      });
  };

const updateMission = async(input) => {
    return axios({
        method:"post",
        url:API_URL + "/update",
        data: input,
        headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
    }).catch((error)=>{
        console.log(`Error From update mission ${error.response}`);
        return error.response;
      }).then((response) => {
          if(response && response.data && response.data.data && response.data.data.errMsg && response.data.data.errMsg!==''){
              alert(response.data.data.errMsg);
          }
        return response.data;
      });
  };

const searchArchiveMissions = async(input) => {
    return axios({
        method:"post",
        url:API_URL + "/getArchiveMissions",
        data: input,
        headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
    }).catch((error)=>{
        console.log(`Error From search archive mission ${error.response}`);
        return error.response;
      }).then((response) => {
//          if(response && response.data && response.data.data && response.data.data.errMsg && response.data.data.errMsg!==''){
//              alert(response.data.data.errMsg);
//          }
        return response.data;
      });
  };

const getMyMissions = async(userId) => {
  return axios({
      method: "get",
      url: API_URL + `/getMyMissions/${userId}`,
      headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
  }).then(response => {
        return response.data;
    }).catch(error => {
        console.log("Error From Get All my missions => ", error.response);
        return error.response;
    });
};
const getMyMissionById = async(userId, missionId) => {
  return axios({
      method: "get",
      url: API_URL + `/getMyMissions/${userId}/${missionId}`,
      headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
  }).then(response => {
        return response.data;
    }).catch(error => {
        console.log("Error From Get my mission => ", error.response);
        return error.response;
    });
};

const terminateMission = async(userId, missionId) => {
  return axios({
      method: "get",
      url: API_URL + `/terminateMission/${userId}/${missionId}`,
      headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
  }).then(response => {
        return response.data.errMsg;
    }).catch(error => {
        console.log("Error From Get my mission => ", error.response);
        return error.response;
    });
};
const getMissionsToJoin = async(userId) => {
  return axios({
      method: "get",
      url: API_URL + `/getMissionsToJoin/${userId}`,
      headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
  }).then(response => {
        return response.data;
    }).catch(error => {
        console.log("Error From Get All my missions => ", error.response);
        return error.response;
    });
};

const joinMission = async(userId, missionId) => {
  return axios({
      method: "get",
      url: API_URL + `/joinMission/${userId}/${missionId}`,
      headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
  }).then(response => {
        return response.data.errMsg;
    }).catch(error => {
        console.log("Error From Get my mission => ", error.response);
        return error.response;
    });
};
const rejectToJoinMission = async(userId, missionId) => {
  return axios({
      method: "get",
      url: API_URL + `/rejectToJoinMission/${userId}/${missionId}`,
      headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
  }).then(response => {
        return response.data.errMsg;
    }).catch(error => {
        console.log("Error From Get my mission => ", error.response);
        return error.response;
    });
};

const exitMission = async(userId, missionId) => {
  return axios({
      method: "get",
      url: API_URL + `/exitMission/${userId}/${missionId}`,
      headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
  }).then(response => {
        return response.data.errMsg;
    }).catch(error => {
        console.log("Error From Get my mission => ", error.response);
        return error.response;
    });
};

const inviteByCallSign = async (userId,missionId, callSign) => {
    let input = {userId:userId, missionId:missionId, callSign: callSign};
    return axios({
        method:"post",
        url:API_URL + "/inviteByCallSign",
        data: input,
        headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
    }).catch((error)=>{
        console.log(`Error From check email ${error.response}`);
        return error.response;
      }).then((response) => {
        return response.data.errMsg;
      });
};

const getAllForMarkers = async(userId) => {
  return axios({
      method: "get",
      url: API_URL + `/getAllForMarkers/${userId}`,
      headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
  }).then(response => {
        return response.data;
    }).catch(error => {
        console.log("Error From Get All my missions => ", error.response);
        return error.response;
    });
};
const getByIdForMarkers = async(userId, missionId) => {
  return axios({
      method: "get",
      url: API_URL + `/getByIdForMarkers/${userId}/${missionId}`,
      headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
  }).then(response => {
        return response.data;
    }).catch(error => {
        console.log("Error From Get my mission => ", error.response);
        return error.response;
    });
};

const getJoinedMissions = async(userId) => {
  return axios({
      method: "get",
      url: API_URL + `/getJoinedMissions/${userId}`,
      headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
  }).then(response => {
        return response.data;
    }).catch(error => {
        console.log("Error From Get my mission => ", error.response);
        return error.response;
    });
};

const joinAndGetByIdForMarker = async(userId, missionId) => {
  return axios({
      method: "get",
      url: API_URL + `/joinAndGetByIdForMarker/${userId}/${missionId}`,
      headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
  }).then(response => {
        return response.data;
    }).catch(error => {
        console.log("Error From Get my mission => ", error.response);
        return error.response;
    });
};

const getMissionDroneDefaults = async() => {
  return axios({
      method: "get",
      url: API_URL + '/getMissionDroneDefaults',
      headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
  }).then(response => {
        return response.data;
    }).catch(error => {
        console.log("Error From Get my mission => ", error.response);
        return error.response;
    });
};

const getDroneStatus = async(userId, missionId, participantUserId, droneId) => {
    let input = {userId:userId,missionId:missionId, droneOwner:participantUserId, droneId:droneId};
  return axios({
      method: "post",
      url:API_URL + "/getDroneStatus",
      data: input,
      headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
  }).then(response => {
        return response.data;
    }).catch(error => {
        console.log("Error From Get drone status => ", error.response);
        return error.response;
    });
};

const startMission = async(missionId, userId, droneId, points) => {
  let input = {missionId:missionId, userId:userId, points:points, droneId:droneId};
    return axios({
        method:"post",
        url:API_URL + "/startMission",
        data: input,
        headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
    }).catch((error)=>{
        console.log(`Error From start mission ${error.response}`);
        return error.response;
      }).then((response) => {
        return response.data.errMsg;
      });
};

const sendCommand = async(missionId, userId, droneId, commandId) => { 
    let input = {missionId:missionId, userId:userId, commandCode:commandId, droneId:droneId};
    return axios({
        method:"post",
        url:API_URL + "/sendCommand",
        data: input,
        headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
    }).catch((error)=>{
        console.log(`Error From start mission ${error.response}`);
        return error.response;
      }).then((response) => {
        return response.data.errMsg;
      });
};

const MissionService = {
    isEmailExist,
    createMission,
    getMyMissions,
    getMyMissionById,
    updateMission,
    getMissionsToJoin,
    terminateMission,
    joinMission,
    rejectToJoinMission,
    exitMission,
    inviteByCallSign,
    searchArchiveMissions,
    getAllForMarkers,
    getByIdForMarkers,
    joinAndGetByIdForMarker,
    getJoinedMissions,
    getMissionDroneDefaults,
    startMission,
    sendCommand,
    getDroneStatus
  };
  
export default MissionService;