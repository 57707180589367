import axios from "axios";
import AuthService from "../services/auth-service"

const API_URL = process.env.REACT_APP_SERVER_URL+"/api/notifications";

const getAllNotifications = async(userId) => {
  return axios({
      method: "get",
      url: API_URL + `/all/${userId}`,
      headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
  }).then(response => {
        return response.data;
    }).catch(error => {
        console.log("Error From Get All notifications => ", error.response);
        return error.response;
    });
};



const markNotificationAsRead = async (userId, participantId, missionId, notificationType) => {
    let input = {userId:userId, participantId:participantId, missionId:missionId, notificationType: notificationType};
    return axios({
        method:"post",
        url:API_URL + "/markAsRead",
        data: input,
        headers: {"Authorization" :`Bearer ${AuthService.getToken()}`}
    }).catch((error)=>{
        console.log(`Error From check notificationRead ${error.response}`);
        return error.response;
      }).then((response) => {
        return response.data.errMsg;
      });
};


const NotificationService = {
    getAllNotifications,
    markNotificationAsRead
    
  };
  
export default NotificationService;