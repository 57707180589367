import axios from "axios";



const API_URL = process.env.REACT_APP_SERVER_URL+"/api/auth";

const login = async(username, password) => {
  return axios.post(API_URL + "/login",{
        "email":username,
        "password":password
      })
    .then((response) => {
      if (response.status === 200) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
        localStorage.setItem("token", response.data.data.token);
      }
      return response.data;
    }).catch(error => {
      console.log("Error From Get All Drones => ", error.response);
      return error.response;
  })
};

const changePassword = async(input) => {
    return axios
      .post(API_URL + "/changepassword",{
        "userId": input.userId,
        "email": input.email,
        "password": input.oldPassword,
        "newPassword": input.newPassword
    }, {headers: {"Authorization" :`Bearer ${getToken()}`}}).then((response) => {
        return response.data;
      })
      .catch(error => {
        console.log("Error From Get All Drones => ", error.response);
        return error.response;
    })
    
  };

  const forgotPassword = async(email) => {
    return axios
      .post(API_URL + "/forgotpassword",{
        "email": email,
    }).then((response) => {
        return response.data;
      })
      .catch(error => {
        console.log("Error From Get All Drones => ", error.response);
        return error.response;
    })
    
  };

const logout = async(userId, radioId) => {
  return axios({
    url:API_URL + "/logout",
    method: 'post',
    data: {
      userId,
      radioId
    },
    headers: {"Authorization" :`Bearer ${getToken()}`},
  }).then((response) => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    return "Logout Success";
  });
};

const getCurrentUser = () => {
  return  JSON.parse(localStorage.getItem("user"));
};

const getToken = () => {
    return localStorage.getItem("token");
  };

const AuthService = {
  login,
  logout,
  getCurrentUser,
  getToken,
  changePassword,
  forgotPassword
}

export default AuthService;