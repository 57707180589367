import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import axios from 'axios';
//let isAlertDisplayed = false;
let isAlertDisplayed = sessionStorage.getItem('isAlertDisplayed') === 'true';


// Add a response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
      
    if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data.message;
        if(errorMessage && errorMessage==='Radio Id/call sign expired'){
            if (!isAlertDisplayed) {
                isAlertDisplayed = true;
                sessionStorage.setItem('isAlertDisplayed', 'true');
                setTimeout(() => {
                    isAlertDisplayed = false;
                    sessionStorage.removeItem('isAlertDisplayed');
                }, 300000); // 5 minutes in milliseconds
          
                alert("Your current session has expired");
                window.location.href = '/login';
            }
        }else{
          return Promise.reject(error);
        }
    }else{
        return Promise.reject(error);
    }
//    else if (error.response && error.response.status === 403) {
//       
//    }
    
  }
);


ReactDOM.render(
  <BrowserRouter basename="/">
      <App />
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
